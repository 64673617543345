import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AppliedRoute from "routes/AppliedRoute";
import help from "routes/Help/help";
import "typeface-open-sans";
import "typeface-quicksand";
import "typeface-roboto";
import "./App.scss";
import en from "./lang/en.json";
import RouteSwitch from "./routes";
import TopBar from "components/TopBar/TopBar";

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, "g"), replacement);
};

class App extends React.Component {
    constructor(props) {
        super(props);

        props.initialize({
            languages: [{ name: "English", code: "en" }],
            translation: {},
            options: { renderToStaticMarkup, defaultLanguage: "en" },
        });
        props.addTranslationForLanguage(en, "en");
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <AppliedRoute
                        name='default.help'
                        props={{ meta: "route.help.meta" }}
                        path={"/help"}
                        exact={false}
                        component={help}
                    ></AppliedRoute>
                    <Route
                        render={(props) => [
                            <Route
                                render={(props) => {
                                    return (
                                        <TopBar
                                            location={props.location}
                                            setCurrentTab={(tab) =>
                                                this.setState({
                                                    currentTab: tab,
                                                })
                                            }
                                        />
                                    );
                                }}
                            />,
                            <RouteSwitch>
                                <Route>
                                    <Redirect to='/'></Redirect>
                                </Route>
                            </RouteSwitch>,
                        ]}
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default withLocalize(App);
