import BemClass from "@upsales/bemclass";
import WseButton from "components/Button/WseButton";
import Text from "components/Text";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import android from "assets/runtimes/android.svg";
import apple from "assets/runtimes/apple.svg";
import cpp from "assets/runtimes/cpp.svg";
import java from "assets/runtimes/java.svg";
import kotlin from "assets/runtimes/kotlin.svg";
import linux from "assets/runtimes/linux.svg";
import swift from "assets/runtimes/swift.svg";
import ue4 from "assets/runtimes/ue4.svg";
import unix from "assets/runtimes/unix.svg";
import windows from "assets/runtimes/windows.svg";
import flutter from "assets/runtimes/flutter.svg";
import { OverviewTitle } from "../Overview";
import "./Runtimes.scss";


const cards = {
    languages: [
        ["Java", java],
        ["Kotlin", kotlin],
        ["Objective-C", apple, true],
        ["Swift", swift],
        ["C/C++", cpp],
        ["Flutter", flutter]
    ],
    platforms: [
        ["Android", android],
        ["iOS", apple],
        ["Linux", linux],
        ["Unix", unix],
        ["Windows", windows],
        ["UE4", ue4]
    ]
};

const Card = ([name, image, smallText]) => {
    const bem = new BemClass("Runtimes__Card");
    return <div className={bem.mod({smallText}).b()}>
        <img src={image} alt={name}/>
        <div>{name}</div>
    </div>
};

class Workflow extends React.Component {
    render() {
        const { translate, className } = this.props;
        const bem = new BemClass("Runtimes", className);

        return (
            <div className={bem.b()} id="runtimes">
                <OverviewTitle>{translate("overview.runtimes.title")}</OverviewTitle>
                
                <Text center>{translate("overview.runtimes.text")}</Text>
                <div className={bem.elem("section").mod("languages").b()}>
                    {cards.languages.map(Card)}
                </div>
                <div className={bem.elem("section").mod("platforms").b()}>
                    {cards.platforms.map(Card)}
                </div>
                <Text center>{translate("overview.about.advantages", null, { renderInnerHtml: true })}</Text>
                <WseButton rotate link={"/resources"}>Downloads</WseButton>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Workflow);
